import { ref } from 'vue';

const search = ref('');

function searchFilter(arr) {
  if (!search.value) return arr;
  return arr.filter((el) => (el.name ? el.name : '').toLowerCase().includes(search.value.toLowerCase()) || el.profiles.email.toLowerCase().includes(search.value.toLowerCase()) || el.id.toString().includes(search.value.toLowerCase()));
}

export function useSearch() {
  return { search, searchFilter };
}
